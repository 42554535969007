import axios, { AxiosResponse } from 'axios';

interface ResponseBackend {
  data: [];
  rows: [] | any;
  message: string;
};

const user = JSON.parse(localStorage.getItem("user")!);

export const getProduct = async (options: any) => {
  const params = Object.entries(options).map(([keys, values]) => `${keys}=${values}`).join('&');
  const response: AxiosResponse = await axios.get(`/api/product?${params}`);
  return response.data;
};

export const getOneProduct = async (uuidProduct: number | string, uuidSupplier: number | string) => {
  const response: AxiosResponse = await axios.get(`/api/product/${uuidProduct}/${uuidSupplier}`);
  return response.data;
};
export const getFileByProduct = async (idProduct: number | string): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.get(`/api/files/${idProduct}`);
  return response.data;
};

export const filterProductByCategory = async (value: number | string) => {
  const response: AxiosResponse = await axios.get(`/api/product?id_category=${value}`);
  return response.data;
};

export const searchProduct = async (uuidSupplier: string | number, productName: string) => {
  const response: AxiosResponse = await axios.get(`/api/product?supplier_uuid=${uuidSupplier}&product_name=${productName}`);
  return response.data;
};

export const createProduct = async (data: {}): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.post(`/api/product`, data);
  return response.data
};

export const updateProduct = async (data: {}, uuidProduct: number | string): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.put(`/api/product/${uuidProduct}`, data);
  return response.data;
};

export const addStockProduct = async (data: {}, idProduct: number | string, uuidSupplier: number | string): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.post(`/api/product/stock?product_uuid=${idProduct}&supplier_uuid=${uuidSupplier}`, data);
  return response.data;
};
export const removeProduct = async (idProduct: number | string, idSupplier: number | string): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.delete(`/api/product/${idProduct}?supplier_uuid=${idSupplier}`);
  return response.data;
};

export const removeFileProduct = async (idProduct: number | string, link: string): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.delete(`/api/files/${idProduct}?link=${link}`);
  return response.data;
};

export const getCategory = async (): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.get(`/api/category`);
  return response.data;
};

export const createCategory = async (data: {}): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.post('/api/category', data);
  return response.data;
};

export const updateCategory = async (data: {}, idCategory: number | string): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.put(`/api/category/${idCategory}`, data);
  return response.data;
};

export const removeCategory = async (idCategory: string | number): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.delete(`/api/category/${idCategory}`);
  return response.data;
};

export const getLabel = async (): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.get('/api/label');
  return response.data;
};

export const createLabel = async (data: {}): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.post(`/api/label`, data);
  return response.data;
};

export const updateLabel = async (data: {}, idLabel: number | string): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.put(`/api/label/${idLabel}`, data);
  return response.data;
};

export const removeLabel = async (idLabel: string | number): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.delete(`/api/label/${idLabel}`);
  return response.data;
};

export const getCountProductCategory = async (uuidSupplier: number | string): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.get(`/api/product/category?uuid=${uuidSupplier}`);
  return response.data;
};

export const changeOldPassword = async (data: any): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.post(`/supplier/auth/old-password`, data);
  return response.data;
};

export const updateLogo = async (link: string, uuidSupplier: string | number, data:{}): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.put(`/api/supplier/logo/${uuidSupplier}?avatar=${link}`, data);
  return response.data;
};

export const reloadInformation = async (uuidSupplier: string | number): Promise<ResponseBackend> => {
  const response = await axios.get(`/api/supplier/${uuidSupplier}`);
  return response.data;
};

export const updateConfigPayment = async (uuidSupplier: number | string, data: {}): Promise<ResponseBackend> => {
  const response: AxiosResponse = await axios.put(`/api/supplier/config/${uuidSupplier}`, data);
  return response.data;
};


export const importProductByFile = async (data:{}): Promise<ResponseBackend> => {
  const response = await axios.post('/api/pruduct/csv', data);
  return response.data;
};

export const getMarketerProduct = async (options: any): Promise<{ rows: any[];  pagination: Pagination}> => {
  const params = Object.entries(options).map(([keys, values]) => `${keys}=${values}`).join('&');
  const response = await axios.get(`/api/suppliersx/marketers?${params}`);
  return response.data;
};

export const getOrderSystem = async (options: {}): Promise<{ rows: OrderSystem[], pagination: Pagination }> => {
  const params = Object.entries(options).map(([keys, values]) => `${keys}=${values}`).join('&');
  const response = await axios.get(`/api/orders?${params}`);
  return response.data;
};

export const getOrderSeller = async (options: {}): Promise<{ rows: OrderSystem[], pagination: Pagination }> => {
  const params = Object.entries(options).map(([keys, values]) => `${keys}=${values}`).join('&');
  const response = await axios.get(`/api/order/seller?${params}`);
  return response.data;
};

export const updateOrderStatus = async (uuid: string, supplierUuid: string, data: any): Promise<{ message: string }> => {
  const response = await axios.put(`/api/order/status/${uuid}/${supplierUuid}`, data);
  return response.data;
};

export const getList = async (table: string): Promise<{ rows: any[], pagination: any }> => {
  const response = await axios.get(`/api/list/${table}`);
  return response.data;
};

export const requestWithdraw = async (data: {}): Promise<ResponseBackend> => {
  const response = await axios.post('/api/withdraw/supplier', data);
  return response.data;
};

export const getWithdraw = async (options: {}): Promise<{ rows: WithdrawType[], pagination: Pagination }> => {
  const params = Object.entries(options).map(([keys, values]) => `${keys}=${values}`).join('&');
  const response = await axios.get(`/api/withdraw/supplier?${params}`);
  return response.data;
};

export const getNotification = async (options: {}): Promise<{ rows: NotificationApp[], pagination: Pagination }> => {
  const params = Object.entries(options).map(([keys, values]) => `${keys}=${values}`).join('&');
  const response = await axios.get(`/api/notification/supplier?${params}`);
  return response.data;
}

export const updateNotification = async (uuid: number) => {
  const response = await axios.put(`/api/notification/notification_suppliers/${uuid}`);
  return response.data;
};

export const getOderDetail = async (uuid: string): Promise<{ rows: OrderSystem[] }> => {
  const response = await axios.get(`/api/order/${uuid}`);
  return response.data;
};

export const getColors = async (): Promise<{ rows: ColorUk[] }> => {
  const response = await axios.get(`/api/colors`);
  return response.data;
}
