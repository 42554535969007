import {
  GET_USER
} from '../type';

interface UserState {
  user: Users;
}

const initialState: UserState = {
  user: {
    uuid: '',
    name_shop: '',
    phone: '',
    amount: 0,
    is_active: 0,
    link_logo: null,
    email: '',
    register_number: null,
    adress_physic: null
  }
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_USER: {
      return { ...state, user: action.payload };
    }
    default:
      return state;
  }
};
