import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { reloadInformation } from '../../api/api';
import { GET_USER } from '../type';

 type Dispatch = ThunkDispatch<
 Record<string, unknown>,
 Record<string, unknown>,
 AnyAction
 > 

export const getInformation = (uuid: string) => async (dispatch: Dispatch) => {
  try {
    const response = await reloadInformation(uuid);
    dispatch({
      type: GET_USER,
      payload: response.rows[0]
    });
  } catch (error) {
    console.warn(error);
  }
};