export const AUTH = "AUTH";
export const ERROR_AUTH = "ERROR_AUTH";
export const GET_MENU = "GET_MENU";
export const REGISTER_USER = "REGISTER_USER";
export const CLEAR_ERROR_AUTH = "CLEAR_ERROR_AUTH";
export const LOGOUT = "LOGOUT";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPDATE_AVATAR = "UPDATE_AVATAR";
export const GET_AVATAR = "GET_AVATAR";
export const LOAD = "LOAD";
export const RESET_PASSWORD_CONFIRM = "RESET_PASSWORD_CONFIRM";
export const lOADING = "lOADING";
export const ERROR_APP = "ERROR_APP";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const CHANGE_MODE = "CHANGE_MODE";
export const GET_CONFIG = "GET_CONFIG";
export const GET_USER = 'GET_USER';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';