import React, { useState } from 'react';
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
// import { Link } from 'react-router-dom';
// import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

//import images
import bell from "../../assets/images/svg/bell.svg";
// import message from "../../assets/images/icon-message.png";


const NotificationDropdown = () => {
  const { t } = useTranslation();
  //Dropdown Toggle
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown);
  };

  //Tab 
  // const [activeTab, setActiveTab] = useState('1');
  // const toggleTab = (tab: any) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab);
  //   }
  // };
  return (
    <React.Fragment>
      <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
        <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
          <i className='bx bx-bell fs-22'></i>
          <span
            className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">0<span
              className="visually-hidden">unread messages</span></span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                </Col>
                <div className="col-auto dropdown-tabs">
                  <span className="badge badge-soft-light fs-13"> 0 </span>
                </div>
              </Row>
            </div>
          </div>
          <div className="w-25 w-sm-50 pt-3 mx-auto">
            <img src={bell} className="img-fluid" alt="user-pic" />
          </div>
          <div className="text-center pb-5 mt-2">
            <h6 className="fs-18 fw-semibold lh-base">{t("any_notification")}</h6>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;