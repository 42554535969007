import { GET_CONFIG } from "../type";

interface InitialConfig {
  paymentMethod: [];
};

const initialState: InitialConfig = {
  paymentMethod: []
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CONFIG:
      return { ...state, paymentMethod: action.payload };
    default:
      return state;
  };
};