import React, { useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { upperFirst } from '../../utils/util';
// SimpleBar
import SimpleBar from 'simplebar-react';
import { notificationApp } from '../../store/actions/notification.action';
import { AppState } from '../../store/store';

const MyCartDropdown = () => {
  const { t } = useTranslation();
  const [isCartDropdown, setIsCartDropdown] = useState(false);
  const { notifications } = useSelector((state: AppState) => state.notification);
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState<Pagination>(notifications.pagination);
  const { user } = useSelector((state: AppState) => state.user);
  const toggleCartDropdown = () => {
    setIsCartDropdown(!isCartDropdown);
  };

  function handlePageClick(data: any) {
    const page = data.selected + 1;
    dispatch(notificationApp({ supplier_uuid: user.uuid, limit: 25, page }) as any);
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isCartDropdown}
        toggle={toggleCartDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="bx bx-shopping-bag fs-22"></i>
          <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-info">
            {notifications.rows.filter((item: any) => item.is_read === 0).length}
            <span className="visually-hidden">{t('recent_order')}</span>
          </span>
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-xl dropdown-menu-end p-0 dropdown-menu-cart"
          aria-labelledby="page-header-cart-dropdown"
        >
          <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 fs-16 fw-semibold">{t('orders')}</h6>
              </Col>
              <div className="col-auto">
                <span className="badge badge-soft-warning fs-13">
                  {' '}
                  <span className="cartitem-badge"> {notifications.rows.length} </span> {t('items')}
                </span>
              </div>
            </Row>
          </div>
          <SimpleBar style={{ maxHeight: '300px' }}>
            <div className="p-2">
            {notifications.rows.filter((field: any) => field.product_name !== null ).map((item: any, i: number) => (
                <div key={i} className="d-block dropdown-item text-wrap dropdown-item-cart px-3 py-2" >
                  <Link to={`/orders/${item.document_uuid}?notif_id=${item.id}`} className="d-flex align-items-center">
                    <img
                      src={item?.link_file}
                      className="me-3 rounded-circle avatar-sm p-2 bg-light"
                      alt="user-pic"
                      style={{
                        objectFit: 'contain'
                      }}
                    />
                    <div className="flex-1">
                    {item.is_read === 1 ?
                      <div className='d-flex flex-column'>
                        <span>
                        {t('new_order')}
                      </span>
                        <span>{upperFirst(item.product_name)}</span>
                      </div> :
                        <div>
                          <h6>
                            {t('new_order')}
                          </h6>
                          <h6> {upperFirst(item.product_name)}</h6>
                        </div>
                      }
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </SimpleBar>
          <Row className="g-0 text-center p-2 text-sm-start align-items-center mb-2 mt-2">
            <Col sm={6}>
              <p className="mb-sm-0 text-muted">Total <span className="fw-semibold">{notifications.rows.length}</span> </p>
            </Col>

            <Col sm={6}>
              <ReactPaginate
                previousLabel={'←'}
                nextLabel={'→'}
                breakLabel={'..'}
                pageCount={pagination.nb_page}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={'pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </Col>
          </Row>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default MyCartDropdown;
