import { AnyAction} from "redux";
import { ThunkDispatch } from "redux-thunk";
import { toast } from 'react-toastify';
import axios, { AxiosResponse } from "axios";
import {
  AUTH,
  ERROR_AUTH,
  CLEAR_ERROR_AUTH,
  LOGOUT,
  lOADING,
} from "../type";
import { NavigateFunction } from "react-router-dom";

 type Dispatch = ThunkDispatch<
  Record<string, unknown>,
  Record<string, unknown>,
  AnyAction
>;



export const loginUser = (formFields:LoginUser, remember:string, navigate: NavigateFunction) => async (dispatch: Dispatch) => {
    
  dispatch({ type: lOADING, payload: true });
  try {
    const response: AxiosResponse = await axios.post("/supplier/auth/login", { email: formFields.email, password: formFields.password, remember });
    dispatch({
      type: AUTH,
      payload: {
        token: response.data.token,
        user: response.data.supplier
      }
    });
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("menus", JSON.stringify(response.data.menu));
    localStorage.setItem("user", JSON.stringify(response.data.supplier));
    dispatch({ type: lOADING, payload: false });
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("token")}`;
    
    navigate("/dashboard");
  } catch (error: any) {
    dispatch({ type: lOADING, payload: false });
    dispatch({
      type: ERROR_AUTH,
      payload: error.response.data?.message || error?.message
    });
  }
};

export const activeAccount = (uuid: string, redirect:string, navigate: NavigateFunction) => async (dispatch: Dispatch) => {
    
  dispatch({ type: lOADING, payload: true });
  try {
    const response: AxiosResponse = await axios.get(`/supplier/auth/${uuid}`);
    dispatch({
      type: AUTH,
      payload: response.data.token
    });
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("menus", JSON.stringify(response.data.menu));
    localStorage.setItem("user", JSON.stringify(response.data.supplier));
    dispatch({ type: lOADING, payload: false });
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("token")}`;
    
    navigate(`${redirect}`);
    toast.dismiss();
    toast.success(`Your account is activate`);
  } catch (error: any) {
    dispatch({type:lOADING, payload:false});
    dispatch({
      type: ERROR_AUTH,
      payload: error.response.data?.message || error?.message
    });
  }
};

export const logOut = (callback:any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: LOGOUT
    });
    localStorage.removeItem("token");
    callback()
  }
};
export const clearError = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_ERROR_AUTH
    });
  }
};

