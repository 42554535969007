import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { menus } from '../../common/menu';
import { upperFirst } from "../../utils/util";

const NavData = () => {
  
  const [isProduct, setIsProduct] = useState(false);
  const [isManage, setIsManage] = useState(false);
  const { t } = useTranslation();
  const [isCurrent, setIscurrent] = useState("dashboard");

  function updateIcon(e: any) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu") as any;
      const iconItems = ul?.querySelectorAll(".nav-icon.active");
      let activeIconsItems = [...iconItems];
      activeIconsItems.forEach((item: HTMLElement) => {
        item.classList.remove("active");
        var id: string = item?.getAttribute("subitems") as string;
        if (document.getElementById(id))
          document.getElementById(id)?.classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("two-column-panel");
    if (isCurrent !== "products") {
      setIsProduct(false);
    }
    if (isCurrent !== "manage") {
      setIsManage(false);
    }
  }, [isProduct, isManage]);

  const menuItems = menus.map((item: any, index: number) => ({
    id: index,
    label: `${t(`${item.name}`)}`,
    icon: item.icon,
    link: item.subItems.length > 0 ? "/#" : "/"+ item.link,
    stateVariables: item.current === "products" ? isProduct :  item.current === "manage" ? isManage: true,
    click: function (e: any) {
      e.preventDefault();
      if (item.subItems.length) {
        if (item.current === "products") {
          setIsProduct(!isProduct);
          setIscurrent("products");
          updateIcon(e);
        }
        if (item.current === "manage") {
          setIsManage(!isManage);
          setIscurrent("manage");
          updateIcon(e);
        }
      }
    },
    subItems: item.subItems.length > 0 && item.subItems.map((child: any, i:number) => {
      return {
        id: i,
        label: `${t(`${child.name}`)}`,
        link: child.children.length ? "/#" : "/" + child.link,
        isChildItem: child.children.length > 0 ? true : false,
        childItems: child.children.length > 0 && child.children.map((thing: any) => {
          return {
            id: thing.id, label: upperFirst(thing.name), link: "/" + thing.name.replace(' ', '-').replace(' ', '-').replace(' ', '-').toLowerCase()
          }
        })
      }
    })

  }));

  return <React.Fragment>{menuItems}</React.Fragment>
};

export default NavData;