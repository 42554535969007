import React from "react";
import { ToastContainer } from "react-toastify";
import Routes from "./routes";
import './assets/scss/themes.scss';
import 'remixicon/fonts/remixicon.css';
import 'boxicons/css/boxicons.min.css';
import 'line-awesome/dist/font-awesome-line-awesome/css/all.min.css';
import '@mdi/font/css/materialdesignicons.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { layoutModeTypes } from "./components/constants/layout";
import i18n from "./i18next";

function App() {
  document.documentElement.setAttribute("data-layout", "vertical");
  document.documentElement.setAttribute("data-sidebar-size", "lg");
  i18n.init();
  
  React.useEffect(() => {
    localStorage.setItem("I18N_LANGUAGE", "en");
    if (window.matchMedia) {
      const colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
      if (colorSchemeQuery.matches) {
        document.documentElement.setAttribute('data-layout-mode', layoutModeTypes.DARKMODE);
        document.documentElement.setAttribute('data-sidebar', layoutModeTypes.DARKMODE);
      } else {
        document.documentElement.setAttribute('data-layout-mode', layoutModeTypes.LIGHTMODE);
        document.documentElement.setAttribute('data-sidebar', layoutModeTypes.LIGHTMODE);
      }
    }
  }, []);
  return (
    <React.Fragment>
      <Routes />
      <ToastContainer
        closeButton={true}
        position="top-center"
        theme="colored"
      />
    </React.Fragment>
  );
}

export default App
