import React, { useEffect, useState } from "react";
import ParticalAuth from "./ParticleAuth";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppState } from "../../store/store";
import { Formik } from "formik";
import * as yup from "yup";
import { loginUser,clearError } from "../../store/actions/auth.action";
import { changeLayoutMode } from "../../store/actions/layout.action";
import LightDark from "../../components/common/LightDark";
import LanguageDropdown from "../../components/common/LanguageDropDown";
import UkLoading from "../../components/common/UkLoading";
import ukuaji from "../../assets/images/ukuaji-dark.png";


const SignIn = () => {
  const { loading, error } = useSelector((state: AppState) => state.auth);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { layoutModeType } = useSelector((state: AppState) => state.layout);
  const [remember, setRemember] = useState("non");
  const Values: LoginUser = {
    email: "",
    password: ""
  };

  const onChangeLayout = (value: string) => {
    dispatch(changeLayoutMode(value) as any);
  }

  const validationSchema = yup.object().shape({
    email: yup.string().email(`${t("error_email")}`).required(`${t("requis_field")}`),
    password: yup.string().required(`${t("requis_field")}`)
  });


  document.title = `${t("signin")}  | ${t("title_app")}`;

  useEffect(() => {
    if (error) {
      toast.dismiss();
      toast.error(error);
      dispatch(clearError() as any)
    }
  }, [error]);

  return (
    <React.Fragment>
      <ParticalAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div> <img src={ukuaji}
                    alt=""
                    height="60" />
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8}
                lg={6}
                xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">{t("welcome")}</h5>
                      <p className="text-muted">{t("desc_signin")}</p>
                    </div>
                    <div className="p-2 mt-4">
                      <Formik
                        initialValues={Values}
                        validationSchema={validationSchema}
                        onSubmit={(values: LoginUser) => {
                          dispatch(loginUser(values, remember, navigate) as any);
                        }}
                      >
                        {({ values, handleChange, handleBlur, handleSubmit, touched, errors, isValid }) => (
                          
                          <Form onSubmit={handleSubmit} >

                            <div className="mb-3">
                              <Label htmlFor="email" className="form-label">{t("email")}</Label>
                              <Input name="email" className="form-control" placeholder="Entrer email" type="email"
                                onChange={handleChange("email")}
                                onBlur={handleBlur("email")}
                                value={values.email}
                                invalid={touched.email && errors.email ? true : false}
                              />
                              {touched.email && errors.email &&
                                <FormFeedback type="invalid">
                                  {errors.email}
                                </FormFeedback>
                              }
                            </div>

                            <div className="mb-3">
                              <div className="float-end">
                                <Link to="/forgot-password" className="text-link">{t("forgot_password")}</Link>
                              </div>
                              <Label className="form-label" htmlFor="password-input">{t("password")}</Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input name="password"
                                  value={values.password}
                                  type={show ? "text" : "password"}
                                  className="form-control pe-5"
                                  placeholder="Enter Password"
                                  onChange={handleChange("password")}
                                  onBlur={handleBlur("password")}
                                  invalid={touched.password && errors.password ? true : false}
                                />
                                {touched.password && errors.password &&
                                  <FormFeedback type="invalid">
                                    {errors.password}
                                  </FormFeedback>
                                
                                }
                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon">
                                  <i className="ri-eye-fill align-middle" onClick={() => setShow(!show)}></i>
                                </button>
                              </div>
                            </div>

                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                value={remember}
                                onChange={() => setRemember(remember === "oui" ? "non" : "oui")}
                                id="auth-remember-check" />
                              <Label className="form-check-label" htmlFor="auth-remember-check">{t("remember")}</Label>
                            </div>

                            <div className="mt-4">
                              <Button color="primary" className="btn btn-primary w-100" type="submit" disabled={!isValid} >
                                {loading ? <UkLoading /> : `${t("btn_signin")}`}
                              </Button>
                            </div>

                            
                          </Form>
                          
                        )}
                      </Formik>
                      <div className="mt-4 justify-content-center d-flex">
                        <LightDark
                          layoutMode={layoutModeType}
                          onChangeLayoutMode={onChangeLayout}
                        />
                        <LanguageDropdown />
                              
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticalAuth>
    </React.Fragment>
  );
};

export default SignIn;