import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import { store } from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './pages/errors/ErrorBundary';

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers.common.Authorization =`Bearer ${localStorage.getItem("token")}`;
axios.defaults.headers.post["Content-Type"] = "application/x-form-urlencoded";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

const root = ReactDOM.createRoot(
  document.getElementById('ukuaji') as HTMLElement
);
root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <Provider store={store}>
        <HashRouter>
          <App />
        </HashRouter>
      </Provider>
    </React.StrictMode>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
