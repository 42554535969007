import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container,  Spinner, Input, Row, Card, Button, Col } from 'reactstrap';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import ParticalAuth from './ParticleAuth';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import LightDark from '../../components/common/LightDark';
import LanguageDropdown from '../../components/common/LanguageDropDown';
import UkLoading from '../../components/common/UkLoading';
import { changeLayoutMode } from '../../store/actions/layout.action';
import ukuaji from "../../assets/images/ukuaji-dark.png";

function ForgetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { layoutModeType } = useSelector((state: AppState) => state.layout);
  const [send, setSend] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [next, setNext] = React.useState(false);
  const [valid, setValid] = React.useState(false);
  const [error, setError] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [newPass, setNewPass] = React.useState({ password: "", confirmPass: "" });
  const [finalStep, setFinalStep] = React.useState(false);
  const [final, setFinal] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  document.title = ` ${t("title_forgot")} | ${t("title_app")}`;
  

  const onChangeLayout = (value: string) => {
    dispatch(changeLayoutMode(value) as any);
  }

  function handleSubmit( ) {
    setLoading(true);
    if (error) setError("");
    if (email.trim() !== "") {
      axios.post("/supplier/auth/reset-password", { email: email })
        .then((data) => {
          setLoading(false);
          setNext(data.data.nextStep);
          setMessage(data.data.message);
        }).catch((error: any) => {
          setLoading(false);
          setError(error.response.data.message);
          setNext(error.response.data.nextStep);
        });
    }
  };


  function confirmCode(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setLoading(true);
    if (error) setError("");
    if (message) setMessage("");
    if (email.trim() !== "" && value) {
      axios.post("/supplier/auth/confirm-code", { code: value, email: email })
        .then((data) => {
          setLoading(false);
          setValid(data.data.auth);
          setMessage(data.data.message)
        }).catch((error: any) => {
          setLoading(false);
          setError(error.response.data.message);
          setValid(error.response.data.auth);
        });
    }
  }

  function changePassword(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setLoading(true);
    if (error) setError("");
    if (message) setMessage("");
    if (newPass.confirmPass === newPass.password) {
      axios.post("/supplier/auth/change-password", { email: email, password: newPass.password })
        .then((data) => {
          setFinal(data.data.message);
          setFinalStep(true);
          setLoading(false);
        }).catch((error) => {
          setLoading(false);
          setError(error.response.data.message);
        });
    } else {     
       alert(t("pass_no_match")); 
    }
  }

  return (
    <React.Fragment>
      <ParticalAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div> <img src={ukuaji}
                    alt=""
                    height="60" />
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8}
                lg={6}
                xl={5}
                    
              >
                <Card className="mt-4">
                   
                  <div className="p-lg-5 p-4">
                    <h5 className="text-primary">{t("title_forgot")}</h5>
                    <p className="text-muted">{t("desc_forgot")}</p>

                    {message && <div className="alert alert-borderless alert-success text-center mb-2 mx-2" role="alert">
                      {message}
                    </div>}
                    {error && <div className="alert alert-borderless alert-danger text-center mb-2 mx-2" role="alert">
                      {error}
                    </div>}
                    {final && <div className="alert alert-borderless alert-success text-center mb-2 mx-2" role="alert">
                      {final}
                    </div>}
                    {finalStep && <Button color="primary" className="w-100" type="submit" onClick={() => navigate('/login')}  >  {t("btn_connect")}  </Button>}
                    <div className="p-2">
                      <form noValidate  >
                        {!next && <div className="mb-4 text-start ">
                          <label htmlFor="email" className="form-label">{t("email")}</label>
                          <input
                            type="email"
                            value={email}
                            onChange={({ target }) => setEmail(target.value)}
                            className="form-control"
                            id="email"
                            placeholder={t("placeholder_email")} />
                        </div>
                        }
                        {next && !valid &&
                          <div className="mb-4 text-start ">
                            <label className="form-label" htmlFor="code">{t("code_six")}</label>
                            <input
                              type="number"
                              className="form-control"
                              id="code"
                              maxLength={6}
                              placeholder={t("code_six")}
                              value={value}
                              // disabled={value.length === 6}
                              onChange={({ target }) => setValue(target.value)}
                            />
                          </div>
                        }
                        {valid && !finalStep && <>
                          <div className="mb-4 text-start ">
                            <label htmlFor="password" className="form-label">{t("new_password")} <span className="text-danger">*</span></label>
                            <div className="position-relative auth-pass-inputgroup mb-3" >
                              <Input
                                type={!show ? "password" : "text"}
                                className="form-control"
                                id="password"
                                placeholder={t("placeholder_password")}
                                required
                                value={newPass.password}
                                onChange={({ target }) => setNewPass({ ...newPass, password: target.value })}
                              />
                              <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addons">
                                <i className={!show ? "ri-eye-fill align-middle " : "ri-eye-off-fill align-middle"} onClick={() => setShow(!show)}></i>
                              </button>
                            </div>
                          </div>

                          <div className="mb-4 text-start mt-1 ">
                            <label htmlFor="confirmpassword" className="form-label"> {t("confirm_password")} <span className="text-danger">*</span></label>
                            <div className="position-relative auth-pass-inputgroup mb-3" >
                              <Input
                                type={!show ? "password" : "text"}
                                className="form-control"
                                id="confirmpassword"
                                placeholder={t("placeholder_confirm")}
                                required
                                value={newPass.confirmPass}
                                onChange={({ target }) => setNewPass({ ...newPass, confirmPass: target.value })}
                                invalid={newPass.password !== newPass.confirmPass ? true : false}
                              />
                              <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon">
                                <i className={!show ? "ri-eye-fill align-middle " : "ri-eye-off-fill align-middle"} onClick={() => setShow(!show)}></i>
                              </button>
                            </div>
                          </div>
                        </>
                        }
                        <div className="text-center mt-4">
                          {!next && <Button color="primary" className="w-100" type="submit" onClick={handleSubmit} disabled={loading} >
                            {loading ? <UkLoading />: t("send_code")}
                          </Button>}
                          {next && !valid && <Button color="primary" className="w-100" type="submit" onClick={confirmCode} disabled={loading}  >
                            {loading ? <UkLoading /> : t("valid_code")}
                          </Button>}
                          {valid && !finalStep && <Button
                            color="primary"
                            className="w-100"
                            type="submit"
                            disabled={newPass.password !== newPass.confirmPass || loading}
                            onClick={changePassword}>
                            {loading ? <UkLoading /> : t("confirm_btn")}
                          </Button>}
                        </div>
                        {next && <div className="float-end">
                          {!finalStep && !valid &&
                            <p
                              onClick={() => handleSubmit()}
                              className='mb-0 fw-semibold text-primary text-decoration-underline' style={{ cursor: "pointer" }} >{t("resend_code")}</p>}
                        </div>}
                      </form>
                    </div>

                    <div className="mt-5 text-center">
                      <p className="mb-0"> {t("i_remember")} . <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> {t("click_here")} </Link> </p>
                    </div>
                  </div>
                  <div className="mt-3 mb-2 justify-content-center d-flex">
                    <LightDark
                      layoutMode={layoutModeType}
                      onChangeLayoutMode={onChangeLayout}
                    />
                    <LanguageDropdown />
                              
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticalAuth>
    </React.Fragment>
  );
};

export default ForgetPassword;