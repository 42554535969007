import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import jwtDecode from "jwt-decode";
import { useNavigate, Outlet } from "react-router-dom";
import SideBar from "./vertical/SideBar";
import Header from "./Header";
import { AppState } from "../store/store";
import { changeLayoutMode } from "../store/actions/layout.action";
import Footer from "./Footer";
import { useIntervale } from "../hooks/useIntervale";
import { logOut } from "../store/actions/auth.action";
import { getConfig } from "../store/actions/config.action";
import { getInformation } from "../store/actions/user.action";
import { notificationApp } from "../store/actions/notification.action";
import { layoutModeTypes } from "../components/constants/layout";

const Layout: React.FC = () => {
  const { t } = useTranslation();
  const [headerClass, setHeaderClass] = useState("");
  const user = JSON.parse(localStorage.getItem('user')!)
  const dispatch = useDispatch();
  const { layoutModeType } = useSelector((state: AppState) => state.layout);
  const { paymentMethod } = useSelector((state: AppState) => state.config);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (layoutModeType) {
      dispatch(changeLayoutMode(layoutModeType) as any);
    }
  }, [layoutModeType, dispatch]);

  useEffect(() => {
    if (window.matchMedia) {
      const colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
      if (colorSchemeQuery.matches) {
        document.documentElement.setAttribute('data-layout-mode', layoutModeTypes.DARKMODE);
        document.documentElement.setAttribute('data-sidebar', layoutModeTypes.DARKMODE);
      } else {
        document.documentElement.setAttribute('data-layout-mode', layoutModeTypes.LIGHTMODE);
        document.documentElement.setAttribute('data-sidebar', layoutModeTypes.LIGHTMODE);
      }
    }
  }, []);


  useIntervale(() => {
    if (token) {
      const decoded: any = jwtDecode(token);
      const now = new Date().getTime()
      const exp = decoded.exp * 1000;
      if (exp < now) {
        dispatch(logOut(() => {
          navigate("/login");
          setTimeout(() => {
            localStorage.clear();
            window.location.reload();
          }, 1000);
        }) as any);
      }
    }
  }, 3000);

  useIntervale(() => {
    dispatch(getInformation(user.uuid) as any);
    dispatch(notificationApp({ supplier_uuid: user.uuid, limit: 25, page: 1 }) as any);
  }, 2000);

  useEffect(() => {
    dispatch(getConfig() as any);
  }, []);

  /*
      call dark/light mode
      */
  const onChangeLayoutMode = (value: string) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value) as any);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass("topbar-shadow");
    } else {
      setHeaderClass("");
    }
  }

  return (
    <React.Fragment>
      <div id="page-wrapper">
        <Header
          headerClass={headerClass}
          layoutMode={layoutModeType}
          onChangeLayout={onChangeLayoutMode}
        />
        <SideBar />
        <div className="main-content">
          <Outlet />
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Layout;