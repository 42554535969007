import React, { useEffect } from "react";
import { NavLink, useResolvedPath, useMatch } from "react-router-dom";
import { Collapse } from "reactstrap";
import { withRouter } from "../../hooks/withRouter";
import navdata from "./LayoutMenuData";

const SideBarContent = (props: any) => {
  const navData = navdata().props.children;
  const resolve = useResolvedPath(props.location.pathname);
  const match = useMatch({ path: resolve.pathname, end: true });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const init = () => {
      const pathName = "/#" + props.location.pathname;
      const ul = document.getElementById("navbar-nav");
      const items = ul?.getElementsByTagName("a") as any;
      let itemsArray = [...items];
      let matchingMenuItem = itemsArray.find((x: any) => {
        return x.pathname === pathName;
      });
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    init()
  }, [props.location.pathname]);


  function activateParentDropdown(item: any) {

    item.classList.add("active");
    let parentCollapseDiv = item.closest(".collapse.menu-dropdown");

    if (parentCollapseDiv) {

      // to set aria expand true remaining
      parentCollapseDiv.classList.add("show");
      parentCollapseDiv.parentElement.children[0].classList.add("active");
      parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
      if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
        parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
        if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
          parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
      }
      return false;
    }
    return false;
  }


  return (
    <React.Fragment>
      {/* menu Items */}
      {(navData || []).map((item: any, key: number) => {
        return (
          <React.Fragment key={key}>
            {/* Main Header */}
            
            {item.subItems ? (
              <li className="nav-item">
                <NavLink
                  onClick={item.click}
                  className={`nav-link menu-link ${match && "text-link"} `}
                  to={item.link ? item.link : "/#"}
                  data-bs-toggle="collapse"
                >
                  <i className={item.icon}></i> <span data-key="t-apps">{item.label}</span>
                </NavLink>
                <Collapse
                  className="menu-dropdown"
                  isOpen={item.stateVariables}
                  id="sidebarApps">
                  <ul className="nav nav-sm flex-column test">
                    {/* subItms  */}
                    {item.subItems && ((item.subItems || []).map((subItem: any, key: number) => (
                      <React.Fragment key={key}>
                        {!subItem.isChildItem ? (
                          <li className="nav-item">
                            <NavLink
                              to={subItem.link ? subItem.link : "/#"}
                              className={`nav-link ${match && "text-link"}`}
                            >
                              {subItem.label}
                            </NavLink>
                          </li>
                        ) : (
                          <li className="nav-item">
                            <NavLink
                              onClick={subItem.click}
                              className={`nav-link ${match && "text-link"}`}
                              to="/#"
                              data-bs-toggle="collapse"
                            > {subItem.label}
                            </NavLink>
                            <Collapse className="menu-dropdown" isOpen={subItem.stateVariables} id="sidebarEcommerce">
                              <ul className="nav nav-sm flex-column">
                                {/* child subItms  */}
                                {subItem.childItems && (
                                  (subItem.childItems || []).map((childItem: any, key: any) => (
                                    <li className="nav-item" key={key}>
                                      <NavLink
                                        to={childItem.link ? childItem.link : "/#"}
                                        className={`nav-link ${match && "text-link"}`}>
                                        {childItem.label}
                                      </NavLink>
                                    </li>
                                  ))
                                )}
                              </ul>
                            </Collapse>
                          </li>
                        )}
                      </React.Fragment>
                    ))
                    )}
                  </ul>

                </Collapse>
              </li>
            ) : (
              <li className="nav-item">
                <NavLink
                  className={`nav-link menu-link ${match && "text-link"}`}
                  to={item.link ? item.link : "/#"}>
                  <i className={item.icon}></i> <span>{item.label}</span>
                </NavLink>
              </li>
            )}
              
            
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default withRouter(SideBarContent);