import _ from 'lodash';

export const formatNumber = new Intl.NumberFormat('en-KE', {
  style: 'currency',
  currency: 'KES'
});

/**
 * @method convertStringToArry
 * @param value String
 * @desc convert the string in the array and remove the duplicate value
 * @return string[]
 */
export const convertStringToArray = (value: string): string[] => {
  return _.uniqWith(Array.from(new Set(value?.split(','))), _.isEqual);
};

/**
 * @method convertStringToArrayColor
 * @param value String
 * @desc convert the string in the array and remove the duplicate value
 */
export const convertStringToArrayColor = (value: string): string[] => {
  const colorWithOutLabel = value?.split(',');
  colorWithOutLabel.map((item: string) => {
    const field = item.split(' ');
    const newData = field.length > 0 ? { nameColor: field[1], color: field[0] } : item;
    return newData;
  });
  return _.uniqWith(colorWithOutLabel, _.isEqual);
};


/**
 * Formats the size
 */
export function formatBytes (bytes: number | any, decimals = 2): any {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const calculateAmount = (data: any[], item: any): number => {
  let amount: any = 0;
  amount = (data.filter((p) => p.product_uuid === item.product_uuid).map((pr) => parseInt(pr.quantityBuy)).reduce((prev, curr) => prev + curr) * data.filter((p) => p.product_uuid === item.product_uuid).map((pr) => parseFloat(pr.price_buy)).reduce((prev, curr) => prev + curr)) || 0;
  return amount;
};

export const totalAmount = (data: OrderSystem[]): number => {
  const tab = data.map((item) => {
    return {
      ...item,
      total: parseFloat(item.price_buy as any) * parseInt(item.items_sales as any)
    };
  });
  const total = tab.map(item => parseFloat(item.total as any)).reduce((prev, curr) => prev + curr);
  return total;
};

/**
 * @method dataUriToBlob
 * @param uri string
 * @description return the blob uri file
 */
export function dataUriToBlob (uri: string | any) {
  const splitUri = uri.split(',');
  const byteString = splitUri[0].includes('base64') ? atob(splitUri[1]) : decodeURI(splitUri[1]);
  const mimeString = splitUri[0].split(':')[1].split(';')[0];

  const ar = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ar[i] = byteString.charCodeAt(i);

  return new Blob([ar], { type: mimeString });
}

/**
 * @method uniq 
 * @param data any[]
 * @param field string
 * @description  remove the duplicate field in the array and return the field uniq
 */

export function uniq(data: any[], field: string) {
  const tab: any[] = [];
  const uniq: any = {};
  data.forEach((item: any) => {
    if (!uniq[field]) {
      uniq[field] = true;
      tab.push(item);
    }
  });
  return tab;
}

/**
 * @method uniqField
 * @param data any[]
 * @description  remove the duplicate field in the array and return the field uniq
 */

 export function uniqField(data: any[]) {
   const tab = [...new Set(data)];
   return tab;
}

/**
 * @method formatColors
 * @param data Array
 * @return Array
 */

export function formatColors(data: any[]): any[]{
  return Array.from(new Set(data.map((item: any) => item.colors)));
}

export function isObject(val: any) {
  return (typeof val === 'object');
}

export function upperFirst(string: string) {
  return string.charAt(0).toLocaleUpperCase() + string.replace(string.charAt(0), '');
}

export const totalAmounts = (data: any[]): number => {
  if (data.length) {
    const tab = data.map((item) => {
      return {
        ...item,
        total: parseFloat(item.price_min) * parseInt(item.items_sales)
      };
    });
    const total = tab.map((item) => parseFloat(item.total)).reduce((prev, curr) => prev + curr);
    return total;
  }
  return 0;
};