import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { GET_NOTIFICATION } from '../type';
import { getNotification } from '../../api/api';

type Dispatch = ThunkDispatch<
  Record<string, unknown>,
  Record<string, unknown>,
  AnyAction
  >;

export const notificationApp = (params: {}) => async (dispatch: Dispatch) => {
  try {
    const response = await getNotification(params);
    dispatch({
      type: GET_NOTIFICATION,
      payload: response
    });
  } catch (error: any) {
    console.warn(error.response.data.message || error.message);
  }
};
