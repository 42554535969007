import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { get, map } from "lodash";

//i18n
import i18n from "../../i18next";
import languages from "../../common/languages";


const LanguageDropdown = () => {
  const [selectedLang, setSelectedLang] = useState("");

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE")!;
    setSelectedLang(currentLanguage);
  }, []);

  const changeLanguageAction = (lang: string | any) => {
    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLang(lang);
  };


  const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);
  const toggleLanguageDropdown = () => {
    setIsLanguageDropdown(!isLanguageDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown isOpen={isLanguageDropdown} toggle={toggleLanguageDropdown} className="ms-1 topbar-head-dropdown header-item">
        <DropdownToggle className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" tag="button">
          <div className="flex-shrink-0 avatar-xs">
            <div className="avatar-title bg-soft-info text-muted fw-bold rounded-circle fs-13">
              {selectedLang.charAt(0).toUpperCase() + selectedLang.charAt(1).toUpperCase()}
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu className="notify-item language py-2">
          {map(Object.keys(languages), key => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${selectedLang === key ? "active" : "none"
                }`}
            >
              <div className="d-flex align-items-center gap-3">
                <div className="flex-shrink-0 avatar-xs">
                  <div className="avatar-title bg-soft-info text-info rounded-circle fs-13">
                    {`${key}.label`.charAt(0).toUpperCase() + `${key}.label`.charAt(1).toUpperCase()}
                  </div>
                </div>
                <span className="align-middle">
                  {get(languages, `${key}.label`)}
                </span>
              </div>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default LanguageDropdown;