import React from 'react';
import { Link } from 'react-router-dom';
// import { Dropdown, DropdownMenu, DropdownToggle, Form } from 'reactstrap';
import LanguageDropdown from '../components/common/LanguageDropDown';
import LightDark from '../components/common/LightDark';
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/ukuaji-dark.png";
import logoLight from "../assets/images/ukuaji-light.png";
import ProfileDropdown from '../components/common/ProfileDropDown';
import FullScreenDropdown from '../components/common/FullScreenDropdown';
import MyCartDropdown from '../components/common/MyCartDropdown';
import NotificationDropdown from '../components/common/NotificationDropdown';

interface HeaderProps {
  headerClass: string;
  layoutMode: string | any;
  onChangeLayout: (mode: any) => void;
};


const Header = ({ headerClass, layoutMode, onChangeLayout }: HeaderProps) => {
  
  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;

    if (windowSize > 767) document.querySelector(".hamburger-icon")!.classList.toggle('open');

    if (document.documentElement.getAttribute('data-layout') === "horizontal") {
      document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
    }

    if (document.documentElement.getAttribute('data-layout') === "vertical") {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove('vertical-sidebar-enable');
        (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
      } else if (windowSize > 1025) {
        document.body.classList.remove('vertical-sidebar-enable');
        (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
      } else if (windowSize <= 767) {
        document.body.classList.add('vertical-sidebar-enable');
        document.documentElement.setAttribute('data-sidebar-size', 'lg');
      }
    }
  };


  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass} >
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">

              <div className="navbar-brand-box horizontal-logo">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="17" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="15" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="15" />
                  </span>
                </Link>
              </div>

              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon">
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>


            </div>

            <div className="d-flex align-items-center">
            
              {/* LanguageDropdown */}
              <LanguageDropdown />

              {/* MyCartDropdwon */}
              <MyCartDropdown />
              <NotificationDropdown />
              {/* FullScreenDropdown */}
              <FullScreenDropdown />

              {/* Light and Dark   */}
              <LightDark layoutMode={layoutMode} onChangeLayoutMode={onChangeLayout} />
              {/* ProfileDropdown */}
              <ProfileDropdown />
              
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;