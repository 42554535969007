import { AnyAction} from "redux";
import { ThunkDispatch } from "redux-thunk";
import axios, { AxiosResponse } from "axios";
import { GET_CONFIG } from "./../type";

type Dispatch = ThunkDispatch<
Record<string, unknown>,
Record<string, unknown>,
AnyAction
>;

export const getConfig = () => async (dispatch: Dispatch) => {
  try {
    const user = JSON.parse(localStorage.getItem("user")!);
    const response: AxiosResponse = await axios.get(`/api/supplier/config/${user?.uuid}`);
    dispatch({
      type: GET_CONFIG,
      payload: response.data?.rows
    });
  } catch (error: any) {
    
  }
}; 