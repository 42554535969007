export const menus:any = [
  {
    current: "dashboard",
    name: "MENU.LABEL.DASHBOARD",
    icon: "bx bxs-dashboard",
    link: "#",
    subItems: []
  },
  {
    current: "products",
    name: "MENU.PRODUCTS",
    icon: "ri-store-3-line",
    link: "#/",
    stateVariable: true,
    subItems: [
      { name: "MENU.ALL_PRODUCT", icon: "", link: "all-product", children: [] },
      { name: "MENU.ADD_PRODUCT", icon: "", link: "add-product", children: [] },
      {name: "MENU.IMPORT-CSV", icon: "", link: "import-csv", children: []},
    ]
  },
  {
    current: "manage",
    name: "MENU.MANAGE",
    icon: "bx bxs-briefcase-alt-2",
    link: "#/",
    stateVariable: true,
    subItems: [
      { name: "MENU.LABEL.ORDERS", icon: "", link: "orders", children: [] },
      { name: "MENU.LABEL.MARKETERS", icon: "", link: "marketers", children: [] },
      {name: "MENU.LABEL.WITHDRAWAL", icon: "", link: "withdrawal", children: []}
    ]
  },
  {
    current: "chats",
    name: "MENU.LABEL.CHATS",
    icon: "bx bx-conversation",
    link: "chats",
    subItems: [],
  },
  {
    name: "MENU.LABEL.CONFIGURATION",
    icon: "bx bx-cog",
    link: "settings",
    subItems: []
  }
]