import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import SignIn from "../pages/auth/SignIn";
import Layout from "../layout";
import UkLoader from "../components/common/UkLoader";

// protect route
import PrivateRoute from "./PrivateRoute";

// public routes
import ForgetPassword from "../pages/auth/ForgetPassword";

// 404 page
import FourByFour from "../pages/errors/FourByFour";

// private routes
const Dashboard = lazy(() => import("../pages/dashboard"));
const ProductList = lazy(() => import("../pages/products/ProducList"));
const AddProduct = lazy(() => import("../pages/products/Addproduct"));
const ProductDetail = lazy(() => import("../pages/products/ProductDetail"));
const EditProduct = lazy(() =>import("../pages/products/EditProduct"));
const Orders = lazy(() => import("../pages/orders"));
const OrderDetail = lazy(() => import('../pages/orders/OrderDetail'));
const Marketers = lazy(() => import("../pages/marketers"));
const Withdraw = lazy(()=> import("../pages/withdraw"));
const Settings = lazy(() => import("../pages/settings"));
const Chats = lazy(() => import("../pages/chats"));
const ProductImport = lazy(() => import("../pages/products/ProductImport"));

const VerifyPage = lazy(() => import("../pages/VerifyAccount"));


const Index = () => {
  return (
    <React.Fragment>
      <Routes>
        {/** the public routes  */}
        <Route path="login" element={<SignIn />} />
        <Route path="forgot-password" element={<ForgetPassword />} />
        <Route path="verify-account/:uuid" element={<Suspense fallback={<UkLoader />}><VerifyPage /></Suspense>} />
        {/** the private routes */}
        <Route element={<PrivateRoute />}>
          <Route element={<Layout />} >
            <Route path="dashboard"
              element={<Suspense fallback={<UkLoader />}><Dashboard /> </Suspense>} />
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="all-product" element={<Suspense fallback={<UkLoader />}><ProductList /></Suspense>} />
            <Route path="all-product/:productUuid" element={<Suspense fallback={<UkLoader />}><ProductDetail /></Suspense>} />
            <Route path="edit/:productUuid" element={<Suspense fallback={<UkLoader />}><EditProduct /></Suspense>} />
            <Route path="add-product" element={<Suspense fallback={<UkLoader />}><AddProduct /></Suspense>} />
            <Route path="import-csv" element={<Suspense fallback={<UkLoader />}><ProductImport /></Suspense>} />
            <Route path="orders" element={<Suspense fallback={<UkLoader />}><Orders /></Suspense>} />
            <Route path="orders/:uuid" element={<Suspense fallback={<UkLoader />}><OrderDetail /></Suspense>} />
            <Route path="marketers" element={<Suspense fallback={<UkLoader />}><Marketers /></Suspense>} />
            <Route path="withdrawal" element={<Suspense fallback={<UkLoader />} ><Withdraw /></Suspense>} />
            <Route path="chats" element={<Suspense fallback={<UkLoader />}><Chats /></Suspense>} />
            <Route path="settings" element={<Suspense fallback={<UkLoader />}><Settings /></Suspense>} />
          </Route>
        </Route>
        <Route path='*' element={<FourByFour />} />
        
      </Routes>
    </React.Fragment>
  );
};

export default Index;