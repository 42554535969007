import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router";

type IComponentWithRouterProp = {
  [x: string]: any;
};

export function withRouter(Component: Function ): Function {
  function ComponentWithRouterProp(
    props: IComponentWithRouterProp
  ): JSX.Element {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} location={location} navigate={navigate} params={params} />;
  }

  return ComponentWithRouterProp;
};