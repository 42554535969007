import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logOut } from '../../store/actions/auth.action';
import { AppState } from '../../store/store';
//import images
import avatar1 from "../../assets/images/user.jpg";
import { formatNumber } from '../../utils/util';

const ProfileDropdown = () => {
  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  const { user } = useSelector((state: AppState) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  function logout() {
    dispatch(logOut(() => {
      navigate("/login");
      setTimeout(() => {
        localStorage.clear();
        window.location.reload();
      }, 1000);
    }) as any);
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user" >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img className="rounded-circle header-profile-user" src={!user?.link_logo ? avatar1 : user?.link_logo}
              alt="avatar" />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text fw-bold">{user && user.name_shop === null ? "---" : user.name_shop}</span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text"><i className="ri-menu-fill"></i></span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">

          <h6 className="dropdown-header">{t("welcome")} {user && user?.name_shop && user?.name_shop}</h6>
          <div className="dropdown-divider"></div>
          <DropdownItem>
            <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Balance : <b>{user && user.amount === null || undefined ? formatNumber.format(0) : formatNumber.format(user.amount)}</b></span></DropdownItem>
          <DropdownItem onClick={() => logout()} >
            <i className="bx bx-exit text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle" data-key="t-logout">{t("logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;