import { Spinner } from 'reactstrap';

const UkLoader: React.FC = () => (
  <div className="text-center mt-5">
    <Spinner type="border" color="primary" className="mt-5">
      {' '}
      Loading{' '}
    </Spinner>
  </div>
);

export default UkLoader;